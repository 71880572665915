.alignleft, .leftalign { float:left; }
.alignright, .rightalign { float:right; }
.ta-c { text-align:center; }

em { font-style:italic; }

* {
  box-sizing: border-box;
}


#{headings()} {
  margin:0;
  font-weight:400;
}

h1 { font-size:2.2em; }
h2 { font-size:1.8em; }
h3 { font-size:1.6em; }
h4 { font-size:1.4em; }
h5 { font-size:1.2em; }
h6 { font-size:1em; }

img {
  display: inline-block;
  max-width:100%;
  height:auto;
}

blockquote {
  display:block;
  background: #fff;
  padding: 15px 20px 15px 45px;
  margin: 0 0 20px;
  position: relative;
  font-size: 13px;
  line-height: 24px;
  color: #666;
  text-align: justify;
  border-left: 15px solid rgba( #000, .4 );
  border-right: 2px solid rgba( #000, .4 );
  box-shadow: 2px 2px 2px #ccc;

  &:before {
    content: "\201C";
    font-family: Georgia, serif;
    font-size: 50px;
    font-weight: bold;
    color: #999;
    position: absolute;
    left: 10px;
    top:35px;
  }

  p {
    margin:0 0 10px;
    &:last-child { margin-bottom:0; }
  }
}
