#sidebar {
  width:33%;
  margin-left:67%;
  header { text-align:right; }
}

#searchform {
  position:relative;
  input {
    width:100%;
    box-sizing: border-box;
  }

  button {
    position:absolute;
    right:8px;
    top:7px;
  }
}

.sidebarBox {
  margin-bottom:40px;
}


@include respond-to( medium-screens ) {

  #sidebar {
    width:100%;
    margin: 3% 0 0 0;
    header { text-align:center; }

    .widget_tag_cloud {
      display: none;
    }
  }

  #userPanel {
    font-size: 1.5em;
  }

}