.btn {
  display: inline-block;
  vertical-align: middle;
  text-align:center;

  &.empty {
    background:transparent;
    border:0;
  }
}

button.btn {
  border:0;
  padding:0;
}