#holderResponsive {
  position:fixed;
  z-index:99;
  padding:3%;
  background:#e7e7e7;
  height:100%;
  width:70%;
  top:0;
  left:-70%;

  #primaryNav, #userPanel, #sidebar {
    float:left;
    width:100%;
    margin:1.5% 0;
  }
}
