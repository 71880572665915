.hentry {
  margin-bottom:40px;
  font-size:13px;
  line-height:28px;
  &:last-child { margin-bottom:0; }
}


.hentry header {
  h1 {
    margin: 0;
    font-weight: 400;
  }

  h6 {
    color:#90ab60;
    font-weight: 400;
    margin: 0;
  }
}