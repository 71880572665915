#content {
  overflow:hidden;
}

#content header {
  padding-bottom:7px;
  margin-bottom:15px;
  border-bottom:1px solid #e0eded;
}

.textArea {
  float:left;
  width:62%;
}

@include respond-to( medium-screens ) {
  .textArea { width:100%; }
}