@mixin clearfix {
  &:before,
  &:after { content: "\0020"; display: block; height: 0; visibility: hidden; }
  &:after { clear:both; }
}

@mixin linear-gradient($top, $bottom){
    background: $top;
    background: linear-gradient(to bottom,  $top 0%,$bottom 100%);
}

@mixin respond-to($media) {
  @if $media == small-screens {
    @media only screen and (max-width: $small-screens) { @content; }
  }

  @else if $media == medium-screens {
    @media only screen and (max-width: $medium-screens - 1) { @content; }
  }

}
