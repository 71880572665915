input,
textarea,
select {
  @include linear-gradient( #f7f7f7, #fff );

  border-radius: 7px;
  box-shadow: inset 0 1px 1px #f2f2f2;
  outline : 0;
  border:1px solid #d9d9d9;
  padding:7px 10px;
  color:#585858;
  &:hover {
    background: #fff;
    opacity: 1;
  }
  &:focus {
    border-color: #bfbfbf;
    color:#000;
    background: #fff;
    opacity: 1;
  }

}

input[type="time"] {
  padding-top:6px;
  padding-bottom:6px;
}

input[type="submit"],
button {
  border-color:#20989a;
  font-weight:700;
  font-size:.9em;
  color:#fff;
  background: #20989a;
}
