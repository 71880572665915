$main-color:#7feff1;
$small-screens: 600px;
$medium-screens: 750px;


@import "utils/normalize";
@import "utils/mixins";
@import "utils/general";
@import "utils/form";
@import "utils/btn";
@import "utils/sprite";

@import "utils/jquery.qtip";

@import "sections/header";
@import "sections/footer";
@import "sections/content";
@import "sections/sidebar";
@import "sections/posts";
@import "sections/social";
@import "sections/testimonials";


@import "sections/responsiveMenu";


html.no-scroll {
  overflow:hidden;
  position:fixed;
  top:0;
}

body {
  font-size: 62.5%;
  border-top:6px solid #222;
  font-family:'Open Sans', verdana, Arial, Helvetica, sans-serif;
  @include linear-gradient( $main-color, #fff );
}


#wrapper,
.inner {
  font-size:1.3em;
  max-width:950px;
  width:100%;
  margin:auto;
  padding:25px;
  position:relative;
}

@include sprites($spritesheet-sprites);


.inner { @include clearfix; }

.clearfix { @include clearfix; }

.inline-icon {
  line-height:18px;
  .i { margin-right:10px; }
  p { margin:0; }
}

ul, li {
  list-style-type:none;
  margin:0;
  padding:0;
}

a {
  color:inherit;
}