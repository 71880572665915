#webFooter {
  background:#222;
  position:relative;
  overflow:hidden;
  &:before {
    content : '\2002';
    background: url('../images/footer.jpg') no-repeat right top;
    width:612px;
    height:309px;
    position:absolute;
    right:0;
    top:50%;
    margin-top:-154px;
  }
  .inner {
    position:relative;
    z-index:2;
  }
  .extra {
    overflow:hidden;
    border-top:1px dashed rgba(#fff, .2);
  }
}

.footerBox {
  line-height:25px;
  color:#fff;
  width:30%;
  margin:0 1.5%;
  float:left;
  header {
    margin-bottom:18px;
    color:#999;
  }
  a {
    color:#fff;
    text-decoration:none;
    &:hover { text-decoration:underline; }
  }
}

.widget_categories li {
  display: inline-block;
}

@include respond-to( small-screens ) {

  #webFooter {
    &:before { display:none; }
  }

  .footerBox {
    width:100%;
    margin:1.5% 0;
  }

}