#webHeader {
  @include clearfix;
  margin-bottom:70px;
  .main { overflow:hidden; }

}

#userPanel {
  width:100%;
  float:left;
  margin-bottom:7%;
  font-weight: 700;

  a {
    text-decoration: none;
  }

  > * {
    float:right;
    clear:both;
    margin:8px 0;
  }
}

#identity {
  width:245px;
  height:69px;
}

#primaryNav {
  li {
    float:left;
    margin:24px 0 0 10px;
    &:first-child{ margin-left:0; }
  }
  a {
    color:#000;
    padding:0 10px;
    line-height:26px;
    display:block;
    text-decoration:none;
    border-radius: 12px;
  }

  .current-menu-item a,
  a:hover {
    background:#222;
    color:#fff;
  }

}

#drivemeNav {
  text-align:center;
  text-transform:uppercase;
  li {
    display: inline-block;
    vertical-align: middle;
    width:24%;
    padding:3%;
    &:first-child { margin-left:0; }
  }

  span {
    display:inline-block;
    // @include single-transition( all );
  }

  .i {
    margin:0 auto 4%;
    display:block;
  }

  .label {
    padding:5px;
    border-radius: 8px;
  }

  a {
    display:block;
    color:#586363;
    font-size:1.1em;
    font-weight:700;
    text-decoration:none;

    &:hover .label {
      background-color:#222;
      color:#fff;
    }
  }
}

#langSwitcher {
  float:right;
  padding:5px;
  background:#fff;
  border-radius: 5px;
  img { display:block; }
  li {
    float:left;
    margin:0 3px;
  }
}

.phoneModal {
  font-size:24px;
  line-height:55px;
  padding:10px 20px;
  a { text-decoration:none; }
}

.sidebarToggle, .sendOnWhatsapp { display:none; }

// Responsive

@include respond-to( medium-screens ) {

  .sendOnWhatsapp {
    display: block;
  }

  .sidebarToggle {
    float:left;
    display:block;
    margin-top:16px;
  }

  #primaryNav {
    margin:0;
    font-size:14px;
    text-align:center;
    li {
      width:100%;
      margin:3px 0;
    }
    a {
      line-height:35px;
      display:block;
      border-radius: 20px;
    }
  }

  #webHeader .main {
    margin-bottom:30px;
  }
  #identity {
    float:none;
    display:block;
    width:170px;
    margin:auto;
  }

  #drivemeNav {
    li { width:47%; }
  }

}

@include respond-to( small-screens ) {

  #drivemeNav {
    li {
      width:100%;
      padding:1%;
      margin:1.5% 0;
      background:#222;
    }
    a { color:#fff; }
    .i { display:none; }
  }

}