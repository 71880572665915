// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$airport-name: 'airport';
$airport-x: 170px;
$airport-y: 0px;
$airport-offset-x: -170px;
$airport-offset-y: 0px;
$airport-width: 170px;
$airport-height: 170px;
$airport-total-width: 381px;
$airport-total-height: 340px;
$airport-image: '../images/sprite.png';
$airport: (170px, 0px, -170px, 0px, 170px, 170px, 381px, 340px, '../images/sprite.png', 'airport', );
$experience-name: 'experience';
$experience-x: 170px;
$experience-y: 170px;
$experience-offset-x: -170px;
$experience-offset-y: -170px;
$experience-width: 170px;
$experience-height: 170px;
$experience-total-width: 381px;
$experience-total-height: 340px;
$experience-image: '../images/sprite.png';
$experience: (170px, 170px, -170px, -170px, 170px, 170px, 381px, 340px, '../images/sprite.png', 'experience', );
$facebook-name: 'facebook';
$facebook-x: 340px;
$facebook-y: 76px;
$facebook-offset-x: -340px;
$facebook-offset-y: -76px;
$facebook-width: 32px;
$facebook-height: 32px;
$facebook-total-width: 381px;
$facebook-total-height: 340px;
$facebook-image: '../images/sprite.png';
$facebook: (340px, 76px, -340px, -76px, 32px, 32px, 381px, 340px, '../images/sprite.png', 'facebook', );
$google-plus-name: 'google_plus';
$google-plus-x: 340px;
$google-plus-y: 44px;
$google-plus-offset-x: -340px;
$google-plus-offset-y: -44px;
$google-plus-width: 32px;
$google-plus-height: 32px;
$google-plus-total-width: 381px;
$google-plus-total-height: 340px;
$google-plus-image: '../images/sprite.png';
$google-plus: (340px, 44px, -340px, -44px, 32px, 32px, 381px, 340px, '../images/sprite.png', 'google_plus', );
$magnifier-name: 'magnifier';
$magnifier-x: 361px;
$magnifier-y: 140px;
$magnifier-offset-x: -361px;
$magnifier-offset-y: -140px;
$magnifier-width: 20px;
$magnifier-height: 20px;
$magnifier-total-width: 381px;
$magnifier-total-height: 340px;
$magnifier-image: '../images/sprite.png';
$magnifier: (361px, 140px, -361px, -140px, 20px, 20px, 381px, 340px, '../images/sprite.png', 'magnifier', );
$prices-name: 'prices';
$prices-x: 0px;
$prices-y: 0px;
$prices-offset-x: 0px;
$prices-offset-y: 0px;
$prices-width: 170px;
$prices-height: 170px;
$prices-total-width: 381px;
$prices-total-height: 340px;
$prices-image: '../images/sprite.png';
$prices: (0px, 0px, 0px, 0px, 170px, 170px, 381px, 340px, '../images/sprite.png', 'prices', );
$sidebar-toggle-name: 'sidebarToggle';
$sidebar-toggle-x: 340px;
$sidebar-toggle-y: 140px;
$sidebar-toggle-offset-x: -340px;
$sidebar-toggle-offset-y: -140px;
$sidebar-toggle-width: 21px;
$sidebar-toggle-height: 21px;
$sidebar-toggle-total-width: 381px;
$sidebar-toggle-total-height: 340px;
$sidebar-toggle-image: '../images/sprite.png';
$sidebar-toggle: (340px, 140px, -340px, -140px, 21px, 21px, 381px, 340px, '../images/sprite.png', 'sidebarToggle', );
$travel-name: 'travel';
$travel-x: 0px;
$travel-y: 170px;
$travel-offset-x: 0px;
$travel-offset-y: -170px;
$travel-width: 170px;
$travel-height: 170px;
$travel-total-width: 381px;
$travel-total-height: 340px;
$travel-image: '../images/sprite.png';
$travel: (0px, 170px, 0px, -170px, 170px, 170px, 381px, 340px, '../images/sprite.png', 'travel', );
$twitter-name: 'twitter';
$twitter-x: 340px;
$twitter-y: 108px;
$twitter-offset-x: -340px;
$twitter-offset-y: -108px;
$twitter-width: 32px;
$twitter-height: 32px;
$twitter-total-width: 381px;
$twitter-total-height: 340px;
$twitter-image: '../images/sprite.png';
$twitter: (340px, 108px, -340px, -108px, 32px, 32px, 381px, 340px, '../images/sprite.png', 'twitter', );
$whatsapp-name: 'whatsapp';
$whatsapp-x: 340px;
$whatsapp-y: 161px;
$whatsapp-offset-x: -340px;
$whatsapp-offset-y: -161px;
$whatsapp-width: 16px;
$whatsapp-height: 16px;
$whatsapp-total-width: 381px;
$whatsapp-total-height: 340px;
$whatsapp-image: '../images/sprite.png';
$whatsapp: (340px, 161px, -340px, -161px, 16px, 16px, 381px, 340px, '../images/sprite.png', 'whatsapp', );
$wireless-name: 'wireless';
$wireless-x: 340px;
$wireless-y: 0px;
$wireless-offset-x: -340px;
$wireless-offset-y: 0px;
$wireless-width: 41px;
$wireless-height: 44px;
$wireless-total-width: 381px;
$wireless-total-height: 340px;
$wireless-image: '../images/sprite.png';
$wireless: (340px, 0px, -340px, 0px, 41px, 44px, 381px, 340px, '../images/sprite.png', 'wireless', );
$spritesheet-width: 381px;
$spritesheet-height: 340px;
$spritesheet-image: '../images/sprite.png';
$spritesheet-sprites: ($airport, $experience, $facebook, $google-plus, $magnifier, $prices, $sidebar-toggle, $travel, $twitter, $whatsapp, $wireless, );
$spritesheet: (381px, 340px, '../images/sprite.png', $spritesheet-sprites, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
